import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.scss";
import Layout from "./components/Layout";
import Bookmark from "./pages/Bookmark";
import Categories from "./pages/Categories";
import Details from "./pages/Details";
import Landing from "./pages/Landing";

function App() {
  return (
    <Router>
      <Layout>
        <div style={{minHeight: "84.5vh"}}>
        <Switch>
          <Route path="/categories/:id">
            <Categories />
          </Route>
          <Route path="/schools/:id">
            <Details />
          </Route>
          <Route path="/saved-schools">
            <Bookmark />
          </Route>
          <Route path="/">
            <Landing />
          </Route>
        </Switch>
        </div>
      </Layout>
    </Router>
  );
}

export default App;
