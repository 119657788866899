import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import "./styles.scoped.scss";
import schoolLogo from "../../assets/img/school-logo.svg";
import schoolImagePng from "../../assets/img/school-img3.png";
import slateOriginalSvg from "../../assets/img/slate-originals.svg";
import Queries from "../../api/query";
import QuickFacts from "./QuickFacts";
import Profile from "./Profile";
import Admissions from "./Admissions";
import Fees from "./Fees";
import SchoolClubs from "./SchoolClubs";
import Features from "./Features";
import Contact from "./Contact";

const { fetchSchoolDetails } = Queries;

function Details() {
  const history = useHistory();
  const [school, setSchool] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const query = queryString.parse(window.location.search);
      const res = await fetchSchoolDetails(query.id);
      setSchool(res);
    }

    fetchData();
  }, []);

  return (
    <React.Fragment>
      <section className="d-body">
        <section className="container">
          <div className="my-4 d-flex justify-content-between align-items-center">
            <div className="text-blue text-capitalize font24">
              <span className="mr-2 click" onClick={() => history.goBack()}>
                <span
                  className="iconify"
                  data-icon="codicon:arrow-left"
                  data-inline="false"
                />
              </span>
              {school?.school_name}
            </div>
            <div className="click text-grey">
              <span className="mr-2">
                <span
                  className="iconify"
                  data-icon="bi:bookmark-fill"
                  data-inline="false"
                />
              </span>
              <span className="font14">Save for later</span>
            </div>
          </div>
          <header className="header">
            <div className="cut-out">
              <div className="school-name">
                <h4>{school?.school_name}</h4>
              </div>
              <div>
                <img src={schoolLogo} alt="" className="logo" />
              </div>
            </div>
          </header>
          <div className="bg-white p-4">
            <ul className="nav nav-pills" id="pills-tab" role="tablist">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  id="pills-QuickFacts-tab"
                  data-toggle="pill"
                  href="#pills-QuickFacts"
                  role="tab"
                  aria-controls="pills-QuickFacts"
                  aria-selected="true"
                >
                  Quick Facts
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="pills-Profile-tab"
                  data-toggle="pill"
                  href="#pills-Profile"
                  role="tab"
                  aria-controls="pills-Profile"
                  aria-selected="false"
                >
                  Profile
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="pills-Admissions-tab"
                  data-toggle="pill"
                  href="#pills-Admissions"
                  role="tab"
                  aria-controls="pills-Admissions"
                  aria-selected="false"
                >
                  Admissions
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="pills-Fees-tab"
                  data-toggle="pill"
                  href="#pills-Fees"
                  role="tab"
                  aria-controls="pills-Fees"
                  aria-selected="false"
                >
                  Fees
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="pills-SchoolClubs-tab"
                  data-toggle="pill"
                  href="#pills-SchoolClubs"
                  role="tab"
                  aria-controls="pills-SchoolClubs"
                  aria-selected="false"
                >
                  School Clubs
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="pills-Features-tab"
                  data-toggle="pill"
                  href="#pills-Features"
                  role="tab"
                  aria-controls="pills-Features"
                  aria-selected="false"
                >
                  Features
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="pills-Contact-tab"
                  data-toggle="pill"
                  href="#pills-Contact"
                  role="tab"
                  aria-controls="pills-Contact"
                  aria-selected="false"
                >
                  Contact
                </a>
              </li>
            </ul>
          </div>

          <div className="row">
            <div className="col-lg-8 col-md-8 col-sm-12">
              <div className="tab-content my-4" id="pills-tabContent">
                <QuickFacts school={school} />
                <Profile school={school} />
                <Admissions school={school} />
                <Fees school={school} />
                <SchoolClubs school={school} />
                <Features school={school} />
                <Contact school={school} />
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 mt-4 school-id">
              <div className="bg-white p-3">
                <div className="d-flex align-items-center">
                  <div>
                    <img
                      src={school?.crest}
                      alt={school?.school_name}
                      className="logo"
                    />
                  </div>
                  <div className="flex-grow text-truncate">
                    <div title={school?.school_name} className="font18 text-truncate text-capitalise">{school?.school_name}</div>
                    <div className="font12 text-grey">last Tuesday</div>
                  </div>
                </div>
                <img
                  src={schoolImagePng}
                  alt=""
                  className="small-school-img py-2"
                />
                <div className="row">
                  <div className="col-lg-10 col-md-10 col-11">
                    <div className="font12 text-blue text-capitalize">
                      THU, NOV 12 AT 6:32 WAT
                    </div>
                    <div>Primary and Secondary Classes Available</div>
                  </div>
                </div>
              </div>
              <img src={slateOriginalSvg} alt="" className="my-3 w-100" />
            </div>
          </div>
        </section>
      </section>
    </React.Fragment>
  );
}

export default Details;
