function formatSchoolFees(params) {
  if (params) {
    const format = `${new Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
      minimumFractionDigits: 0
    }).format(params?.min)}-${new Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
      minimumFractionDigits: 0
    }).format(params?.max)}`;

    return format;
  }
  return ""
}

export default function QuickFacts({ school }) {
  return (
    <div
      className="tab-pane fade show active"
      id="pills-QuickFacts"
      role="tabpanel"
      aria-labelledby="pills-QuickFacts-tab"
    >
      <div className="bg-white p-4 br-5">
        <div className="row">
          <div className="col-lg-4 col-md-6 col-6 my-4">
            <div className="text-grey font12">Location</div>
            <div className="font14">{school?.quick_facts?.location}</div>
          </div>
          <div className="col-lg-4 col-md-6 col-6 my-4">
            <div className="text-grey font12">Type</div>
            <div className="font14">{school?.quick_facts?.type}</div>
          </div>
          <div className="col-lg-4 col-md-6 col-6 my-4">
            <div className="text-grey font12">Language</div>
            <div className="font14">{school?.quick_facts?.language}</div>
          </div>
          <div className="col-lg-4 col-md-6 col-6 my-4">
            <div className="text-grey font12">Fees</div>
            <div className="font14 text-truncate">
              {formatSchoolFees(school?.quick_facts?.fees)}
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-6 my-4">
            <div className="text-grey font12">Curriculum</div>
            <div className="font14">{school?.quick_facts?.curriculum}</div>
          </div>
          <div className="col-lg-4 col-md-6 col-6 my-4">
            <div className="text-grey font12">Students</div>
            <div className="font14">N/A</div>
          </div>
          <div className="col-lg-4 col-md-6 col-6 my-4">
            <div className="text-grey font12">Phone</div>
            <div className="font14 overflow-auto">
              {school?.quick_facts?.phone?.split(",")?.map((phone) => (
                <div>{phone}</div>
              ))}{" "}
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-6 my-4">
            <div className="text-grey font12">Email</div>
            <div className="font14">{school?.quick_facts?.email || "N/A"}</div>
          </div>
          <div className="col-lg-4 col-md-6 col-6 my-4">
            <div className="text-grey font12">Entrance Date</div>
            <div className="font14">N/A</div>
          </div>
          <div className="col-lg-4 col-md-6 col-6 my-4">
            <div className="text-grey font12">Website</div>
            <div className="text-truncate">
              <a
                href={school?.quick_facts?.website}
                className="font14"
                title={school?.quick_facts?.website}
                target="_blank"
                rel="noreferrer"
              >
                {school?.quick_facts?.website}
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-6 my-4">
            <div className="text-grey font12">Grades</div>
            <div className="font14">{school?.quick_facts?.grades}</div>
          </div>
          <div className="col-lg-4 col-md-6 col-6 my-4">
            <div className="text-grey font12">Lodging</div>
            <div className="font14">{school?.quick_facts?.lodging}</div>
          </div>
          <div className="col-lg-4 col-md-6 col-6 my-4">
            <div className="text-grey font12">Co-Ed</div>
            <div className="font14">{school?.quick_facts?.co_ed}</div>
          </div>
        </div>
      </div>
      <div className="bg-blue px-4 py-1 font14 my-4 br-5 d-flex justify-content-between">
        <span className="text-white">
          This information is collated independently by Slate Fact Finders
        </span>
        <span className="text-yellow">
          <span
            className="iconify"
            data-icon="foundation:info"
            data-inline="false"
          ></span>
        </span>
      </div>
    </div>
  );
}
