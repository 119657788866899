import axios from "axios";
import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import { BoxLoading } from "react-loadingg";
import queryString from "query-string";
import classNames from "classnames";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import "./styles.scoped.scss";
import schoolLogo from "../../assets/img/school-logo.svg";
import queries from "../../api/query";
import paginate from "../../services/paginate";
import SuggestContainer from "../../components/SuggestionContainer";

// getLocationName uses MapBox geocoding API to get location name using the current longitude and latitude
const getLocationName = async (lat, lng) => {
  const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${process.env.REACT_APP_MAPBOX_API_KEY}`;
  const resp = await axios.get(url);
  console.log(resp);
};

function Categories() {
  const history = useHistory();
  const [suggestions, setSuggestions] = useState([]);
  const [schools, setSchools] = useState([]);
  const [filters, setFilter] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState([]);
  const [location, setLocation] = useState("");
  const [curriculum, setCurriculum] = useState("");
  const [type, setType] = useState("");
  const [fee, setFee] = useState("");
  const queryClient = useQueryClient();

  const urlLocation = useLocation();

  // Fetch data
  const { isLoading, error, data: categories } = useQuery(
    [`categories`, urlLocation.search],
    async function fetchData() {
      const query = queryString.parse(urlLocation.search);
      const res = await queries.fetchSchools(`${queryString.stringify(query)}`);
      return res;
    }
  );

  // handleRefineSearch modifies the current URL to re-filter the current schools
  const handleRefineSearch = () => {
    const stringified = queryString.stringify(
      {
        ...queryString.parse(urlLocation.search),
        location: location,
        type: type.toLowerCase(),
        curriculum: curriculum.toLowerCase(),
        "max-fee": fee.split("-")[1],
        "min-fee": fee.split("-")[0],
      },
      { skipNull: true, skipEmptyString: true }
    );
    queryClient
      .invalidateQueries("categories", { exact: true })
      .then((success) => {
        window.location.search = stringified;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // handlePagination uses the current schools count and active page to paginate schools to be rendered
  const handlePagination = (items, page) => {
    // paginate schools before displaying
    const pageObject = paginate(items.length, page, 8);
    // slice selected start and end index for paginated objects
    setSchools(items.slice(pageObject.startIndex, pageObject.endIndex + 1));
    setPages(pageObject.pages);
  };

  // handlerSearch
  const handleSearch = (e) => {
    if(e.target.value.trim().length === 0){
      setSuggestions([])
      return
    }
    const results = schools.filter((school) =>
      school.name.toLowerCase().startsWith(e.target.value.toLowerCase())
    );
    setSuggestions(results);
  };

  useEffect(() => {
    const hash = queryString.parse(urlLocation.hash);
    if(hash?.page){
      setCurrentPage(Number(hash?.page));
    }
  }, [urlLocation.hash])

  // Pagination/Filter
  useEffect(() => {
    const curriculums = new Set();
    const types = new Set();
    const fees = new Set();

    if (categories) {
      categories.data.forEach((element) => {
        curriculums.add(element.curriculum);
        types.add(element.type);
        fees.add({ min: element?.fees?.min, max: element?.fees?.max });
      });

      // sort min-max fees and create filter display
      const feesArray = Array.from(fees).sort((a, b) => b - a);
      const feesFilter = feesArray.map((f) => ({
        ...f,
        range: `${new Intl.NumberFormat("en-NG", {
          style: "currency",
          currency: "NGN",
        }).format(f.min)}-${new Intl.NumberFormat("en-NG", {
          style: "currency",
          currency: "NGN",
        }).format(f.max)}`,
      }));

      // set page filters
      setFilter({
        curriculums: Array.from(curriculums),
        types: Array.from(types),
        fees: Array.from(new Set(feesFilter)).sort(),
      });

      handlePagination(categories.data, currentPage);
    }

    return () => {
      queryClient.invalidateQueries("categories", { exact: true });
    };
  }, [currentPage, categories, queryClient]);

  // Geolocation side-effect
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          getLocationName(latitude, longitude);
        },
        (err) => {
          console.log(err);
        },
        {
          enableHighAccuracy: true,
        }
      );
    }
  }, []);

  if (isLoading)
    return (
      <div
        style={{ zIndex: "-1" }}
        className="position-absolute h-100 bg-dark w-100 top-0"
      >
        <LoadingOverlay
          active={true}
          className="h-100"
          spinner={<BoxLoading size="large" color="#ffffff" />}
        />
      </div>
    );

  if (error)
    return (
      <div className="h-100 w-100 d-flex justify-content-center align-items-center">
        {"An error has occurred: " + error.message}
      </div>
    );

  return (
    <React.Fragment>
      <section className="d-body">
        <section className="container">
          <div className="my-4 text-blue font24">
            <span className="mr-2 click" onClick={() => history.goBack()}>
              <span
                className="iconify"
                data-icon="codicon:arrow-left"
                data-inline="false"
              />
            </span>
            Primary Schools
          </div>
          <div className="refine-search my-4 px-3">
            <div className="row py-1">
              <div className="col-lg-4 col-md-6 col-sm-12 py-3 my-1">
                <input
                  style={{ cursor: "text" }}
                  type="text"
                  className="form-control pl-4 w-100"
                  placeholder="Search School"
                  onChange={handleSearch}
                />
                {suggestions.length > 0 && (
                  <SuggestContainer>
                    <div className="d-block position-sticky text-right px-2 py-1">
                      <small className="text-muted">
                        Results: {suggestions.length}
                      </small>
                    </div>
                    <ul className="list-group list-group-flush">
                      {suggestions?.map((school) => (
                        <li
                          key={school.id}
                          className="list-group-item text-truncate border-top-0 border-bottom"
                        >
                          <Link
                            to={`/schools/${school.name
                              .toLowerCase()
                              .split(" ")
                              .join("-")}/?id=${school.id}`}
                            style={{ textDecoration: "none" }}
                          >
                            <small>{school.name}</small>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </SuggestContainer>
                )}
                <span className="search-icon" style={{ width: "25px" }}>
                  <span
                    className="iconify ml-2 pt-0"
                    data-icon="feather:search"
                    data-inline="false"
                  />
                </span>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 py-2">
                <div className="d-flex flex-wrap justify-content-between">
                  <div className="mr-1 mt-1">
                    <div className="font12 text-grey">Location</div>
                    <select
                      value={location}
                      onChange={(e) => setLocation(e.target.value)}
                    >
                      <option value="Abuja">Abuja</option>
                      <option value="Lagos">Lagos</option>
                      <option value="Kano">Kano</option>
                    </select>
                  </div>
                  <div className="mr-1 mt-1">
                    <div className="font12 text-grey">Curriculum</div>
                    <select
                      value={curriculum}
                      onChange={(e) => setCurriculum(e.target.value)}
                    >
                      {filters?.curriculums?.map((curriculum) => (
                        <option key={curriculum} value={curriculum}>
                          {curriculum}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mr-1 mt-1">
                    <div className="font12 text-grey">Type</div>
                    <select
                      value={type}
                      onChange={(e) => setType(e.target.value)}
                    >
                      {filters?.types?.map((type) => (
                        <option key={type} value={type}>
                          {type}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mr-1 mt-1">
                    <div className="font12 text-grey">Fees</div>
                    <select
                      value={fee}
                      onChange={(e) => setFee(e.target.value)}
                    >
                      {filters?.fees?.map((fee, index) => (
                        <option key={index} value={`${fee.max}-${fee.min}`}>
                          {fee.range}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-12 py-3">
                <button
                  className="btn btn-blue mt-1 w-100"
                  onClick={handleRefineSearch}
                >
                  Refine Search
                </button>
              </div>
            </div>
          </div>
          <header id="categoriesHeader" className="header">
            <div className="cut-out">
              <div className="school-name">
                <h3>Marriot Primary School</h3>
              </div>
              <div>
                <img src={schoolLogo} alt="" className="logo" />
              </div>
            </div>
          </header>
          <div id="schools" className="row mb-5 pb-5">
            {schools.length > 0 &&
              schools.map((school) => (
                <div
                  key={school.id}
                  className="col-lg-3 col-md-4 col-sm-6 my-3"
                >
                  <div className="school-card h-100">
                    <Link
                      to={`/schools/${school.name
                        .toLowerCase()
                        .split(" ")
                        .join("-")}/?id=${school.id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <img src={school.crest} alt="" className="school-img" />
                      <div className="p-4">
                        <p className="text-truncate mb-0 font18">
                          {school.name}
                        </p>
                        <p className="text-truncate text-grey font-light">
                          {school.address}
                        </p>
                        {/* <div className="d-flex flex-wrap justify-content-between"> */}
                        <div className="d-flex mb-2 flex-wrap justify-content-between">
                          <div className="mr-2">
                            <div className="font12 text-grey">Curriculum</div>
                            <div className="text-truncate font13 text-blue">
                              {school.curriculum}
                            </div>
                          </div>
                          <div>
                            <div className="font12 text-grey">Type</div>
                            <div className="text-truncate font13 text-blue">
                              {school.type}
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center mb-2 flex-wrap justify-content-between">
                          <div className="mr-2">
                            <div className="font12 text-grey">Co-Ed</div>
                            <div className="text-truncate font13 text-blue">
                              {school.co_ed}
                            </div>
                          </div>
                          <div>
                            <span
                              className="iconify"
                              data-icon="bi:bookmark-fill"
                              data-inline="false"
                            />
                          </div>
                        </div>
                        {/* </div> */}
                      </div>
                    </Link>
                  </div>
                </div>
              ))}
          </div>
          <hr />
          {/* pagination */}
          {pages.length > 1 && (
            <nav aria-label="schools pagination" className="my-5">
              <ul className="pagination pagination-md">
                {pages.map((page) => (
                  <li
                    key={page}
                    className={classNames("page-item", {
                      active: currentPage === page,
                    })}
                  >
                    <a
                      className="page-link"
                      href={`#page=${page}`}
                      tabIndex="-1"
                      onClick={() => {
                        setCurrentPage(page);
                        window.scrollTo({
                          left: 0,
                          top: 280,
                        });
                      }}
                    >
                      {page}
                    </a>
                  </li>
                ))}
              </ul>
            </nav>
          )}
        </section>
      </section>
    </React.Fragment>
  );
}

export default Categories;
