import React, { useEffect, useState, useCallback } from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import SuggestContainer from "../../components/SuggestionContainer";
import "./styles.scoped.scss";
import schoolLogo from "../../assets/img/school-logo.svg";
import earlyPng from "../../assets/img/early.png";
import primaryPng from "../../assets/img/primary.png";
import onlinePng from "../../assets/img/online.png";
import collegesPng from "../../assets/img/college.png";
import secondaryPng from "../../assets/img/secondary.png";
import blogPng from "../../assets/img/blog.png";
import playPng from "../../assets/img/play.png";
import kidProfilePng from "../../assets/img/kid-profile.svg";
import queries from "../../api/query";

function Landing() {
  const [search, setSearch] = useState("");
  const [options, setOptions] = useState([]);


  useEffect(() => {
    window.addEventListener('click', onClickOutsideHandler);

    return () => {
      window.removeEventListener('click', onClickOutsideHandler);
    }
  }, []);

  const getAPISuggestions = useCallback(
    _.debounce(async (searchTerm) => {
      try {
        const q = searchTerm.trim()
        if (q.length !== 0) {
          const result = await queries.fetchSchools(`name=${q}`);
          setOptions(result.data);
        } else {
          setOptions([]);
        }
      } catch (error) {
        console.log(error);
      }
    }, 500),
    []
  );

  const handleSearch = (e) => {
    setSearch(e.target.value);
    getAPISuggestions(search);
  };

  const onClickOutsideHandler = (event) => {
    setOptions([])
  }

  return (
    <React.Fragment>
      <section class="d-body">
        <section class="pt-5 container">
          <header className="header">
            <div>
              <h3>Marriot College Virtual Open House</h3>
              <div>
                <span class="mr-3"> Abuja Campus: November 21 </span>
                <span> Lagos Campus: November 21 </span>
              </div>
              <span> go. marriot.ng/openhouse </span>
            </div>
            <div>
              <img src={schoolLogo} alt="" class="logo" />
            </div>
          </header>
          <div class="my-4 p-r">
            <span class="search-icon">
              <span
                class="iconify"
                data-icon="feather:search"
                data-inline="false"
              ></span>
            </span>
            <input
              type="text"
              placeholder="Search for Schools"
              class="form-control find-school-input"
              value={search}
              onChange={handleSearch}
            />
            {options.length > 0 && (
              <SuggestContainer>
                <div className="d-block position-sticky text-right px-2 py-1">
                  <small
                    style={{ position: "sticky", top: 0 }}
                    className="text-muted"
                  >
                    Results: {options.length}
                  </small>
                </div>
                <ul className="list-group list-group-flush">
                  {options?.map((option) => (
                    <li
                      key={option.id}
                      className="list-group-item text-truncate border-top-0 border-bottom"
                    >
                      <Link
                        to={`/schools/${option.name
                          .toLowerCase()
                          .split(" ")
                          .join("-")}/?id=${option.id}`}
                        style={{ textDecoration: "none" }}
                      >
                        <small>{option.name}</small>
                      </Link>
                    </li>
                  ))}
                </ul>
              </SuggestContainer>
            )}
          </div>
          <div class="row mb-5">
            <div class="col-lg-8 col-md-8 col-sm-12">
              <div class="row category">
                <div class="col-6 p-r my-2 click">
                  <Link to="/categories/early-years?grades=early-years">
                    <img src={earlyPng} alt="" />
                    <span class="content">Early Years</span>
                  </Link>
                </div>

                <div class="col-6 p-r my-2 click">
                  <Link to="/categories/primary-school?grades=primary">
                    <img src={primaryPng} alt="" />
                    <span class="content">Primary School</span>
                  </Link>
                </div>
                <div class="col-6 p-r my-2 click">
                  <Link to="/categories/secondary-school?grades=secondary">
                    <img src={secondaryPng} alt="" />
                    <span class="content">Secondary School</span>
                  </Link>
                </div>
                <div class="col-6 p-r my-2 click">
                  <Link to="/categories/online-learning">
                    <img src={onlinePng} alt="" />
                    <span class="content">Online Learning</span>
                  </Link>
                </div>
                <div class="col-6 p-r my-2 click">
                  <Link to="/categories/colleges?grades=colleges">
                    <img src={collegesPng} alt="" />
                    <span class="content">Colleges</span>
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 blog">
              <div class="p-r my-3">
                <span class="duration">3:23</span>
                <img src={blogPng} alt="" class="blog-img" />
                <div class="play">
                  <img src={playPng} alt="" />
                </div>
                <div class="content font-light">
                  <div class="mb-2">
                    <span class="profile mr-2">
                      <img src={kidProfilePng} alt="" />
                    </span>
                    <span class="text-grey"> Slate Blog </span>
                  </div>
                  The Ultimate Guide to Becoming a Great Designer (Part 2 of 3)
                  of two lines that you’ll see that it’...
                  <div class="font12 text-grey mt-2">
                    Jun 6, 2020 • 11 mins read
                  </div>
                </div>
              </div>
              <div class="p-r my-3">
                <span class="duration">3:23</span>
                <img src={blogPng} alt="" class="blog-img" />
                <div class="play">
                  <img src={playPng} alt="" />
                </div>
                <div class="content font-light">
                  <div class="mb-2">
                    <span class="profile mr-2">
                      <img src={kidProfilePng} alt="" />
                    </span>
                    <span class="text-grey"> Slate Blog </span>
                  </div>
                  The Ultimate Guide to Becoming a Great Designer (Part 2 of 3)
                  of two lines that you’ll see that it’...
                  <div class="font12 text-grey mt-2">
                    Jun 6, 2020 • 11 mins read
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </React.Fragment>
  );
}

export default Landing;
