import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./styles.scoped.scss";
import logoWhiteSvg from "../../assets/img/logo-white.svg";
import profileSvg from "../../assets/img/profile.svg";

function Navbar() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const loginElement = (
    <ul className="navbar-nav align-items-center">
      <li
        className="nav-item"
        onClick={() => setIsLoggedIn((isLoggedIn) => !isLoggedIn)}
      >
        <a
          className="nav-link btn btn-md btn-primary rounded-pill py-2"
          href="#!"
        >
          Login
        </a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="#!">
          Sign Up
        </a>
      </li>
    </ul>
  );

  return (
    <React.Fragment>
      <section className="first-nav">
        <nav className="navbar navbar-expand-lg navbar-dark">
          <div className="container">
            <a className="navbar-brand" href="/student">
              <img src={logoWhiteSvg} alt="" />
            </a>
            <div
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span
                className="iconify text-grey"
                data-icon="gg:menu-cheese"
                data-inline="false"
              ></span>
            </div>
            <div
              className="collapse navbar-collapse d-sm-none"
              id="navbarNavDropdown"
            >
              <ul className="navbar-nav ml-auto">
                <li className="nav-item active">
                  <a className="nav-link" href="#!">
                    Home{" "}
                  </a>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#!"
                    id="navbarDropdownMenuLink"
                    data-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Courses
                    <span className="ml-2">
                      <i className="fa fa-angle-down"></i>
                    </span>
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <a className="dropdown-item" href="/student">
                      My Dashboard
                    </a>
                    <a className="dropdown-item" href="/student/className/lessons">
                      My Course{" "}
                    </a>
                    <a className="dropdown-item" href="/student/exercise">
                      My Exercises{" "}
                    </a>
                    <a className="dropdown-item" href="#!">
                      Preferences{" "}
                    </a>
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#!"
                    id="navbarDropdownMenuLink"
                    data-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Schools{" "}
                    <span className="ml-2">
                      <i className="fa fa-angle-down"></i>
                    </span>
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <a className="dropdown-item" href="#!">
                      {" "}
                      Early Years{" "}
                    </a>
                    <a className="dropdown-item" href="#!">
                      Primary
                    </a>
                    <a className="dropdown-item" href="#!">
                      Secondary
                    </a>
                    <a className="dropdown-item" href="#!">
                      Clubs/After School Programs
                    </a>
                  </div>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#!">
                    Shop
                  </a>
                </li>
              </ul>
              {/* show only if user is Logged In */}
              {isLoggedIn ? (
                <div className="dropdown">
                  <div
                    className="click dropdown-toggle d-flex align-items-center"
                    id="dropdownMenudiv"
                    data-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <div className="ml-3">
                      <p className="mb-0 font12 font-light">Hello, Jonathan</p>
                      <p className="mb-0 font14 font-bold">Settings</p>
                    </div>
                    <div className="profile">
                      <span className="px-2">
                        <i className="fa fa-angle-down"></i>
                      </span>
                      <img src={profileSvg} className="rounded-circle" alt="" />
                    </div>
                  </div>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenudiv"
                  >
                    <a
                      className="dropdown-item click font12"
                      href="/admin/profile"
                    >
                      Profile
                    </a>
                    <a className="dropdown-item click font12" href="#!">
                      Logout
                    </a>
                  </div>
                </div>
              ) : (
                loginElement
              )}
            </div>

            <div
              className="collapse navbar-lg-collapse d-md-none"
              id="navbarNavDropdown"
            >
              <div className="d-flex align-items-center mt-3 mb-2">
                <div className="profile ml-2">
                  <img src={profileSvg} className="rounded-circle" alt="" />
                </div>
                <div className="ml-3">
                  <p className="mb-0 font12 font-light">Hello, Jonathan Ben</p>
                </div>
              </div>

              <ul className="navbar-nav ml-auto">
                <li className="nav-item active">
                  <a className="nav-link" href="/">
                    Home{" "}
                  </a>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#!"
                    id="navbarDropdownMenuLink"
                    data-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Courses
                    <span className="ml-2">
                      <i className="fa fa-angle-down"></i>
                    </span>
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <a className="dropdown-item" href="/student">
                      My Dashboard
                    </a>
                    <a className="dropdown-item" href="/student/className/lessons">
                      My Course{" "}
                    </a>
                    <a className="dropdown-item" href="/student/exercise">
                      My Exercises{" "}
                    </a>
                    <a className="dropdown-item" href="#!">
                      Preferences{" "}
                    </a>
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#!"
                    id="navbarDropdownMenuLink"
                    data-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Schools{" "}
                    <span className="ml-2">
                      <i className="fa fa-angle-down"></i>
                    </span>
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <a className="dropdown-item" href="#!">
                      {" "}
                      Early Years{" "}
                    </a>
                    <a className="dropdown-item" href="#!">
                      Primary
                    </a>
                    <a className="dropdown-item" href="#!">
                      Secondary
                    </a>
                    <a className="dropdown-item" href="#!">
                      Clubs/After School Programs
                    </a>
                  </div>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#!">
                    Shop
                  </a>
                </li>
                <li className="nav-item">
                  <div className="dropdown">
                    <div
                      className="click dropdown-toggle d-flex align-items-center"
                      id="dropdownMenudiv"
                      data-toggle="dropdown"

                      aria-expanded="false"
                    >
                      <div className="ml-3">
                        <p className="mb-0 font14 font-bold">Settings</p>
                      </div>
                      <span className="px-2">
                        <i className="fa fa-angle-down"></i>
                      </span>
                    </div>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenudiv"
                    >
                      <a
                        className="dropdown-item click font12"
                        href="/admin/profile"
                      >
                        Profile
                      </a>
                      <a className="dropdown-item click font12" href="#!">
                        Logout
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </section>
      <section className="second-nav">
        <div className="container">
          <Link
            to="/saved-schools"
            style={{ textDecoration: "none" }}
            className="text-right d-flex justify-content-end align-items-center click"
          >
            <span>My Saved Schools</span>
            <span className="notification-count">4</span>
          </Link>
        </div>
      </section>
    </React.Fragment>
  );
}

function Footer() {
  return (
    <React.Fragment>
      <div className="bg-grey mt-auto">
        <footer className="footer">
          <div className="container py-space">
            <div className="row">
              <div className="col-lg-1 col-sm-12"></div>
              <div className="col-lg-2 col-sm-12 mb-3 text-center">
                <img src={logoWhiteSvg} alt="slate logo" height="45" />
              </div>
              <div className="col-lg-2 col-6 my-3">
                <p className="font-bold text-white">About</p>
                <p className="mb-1">Our mission</p>
                <p className="mb-1">Our story</p>
                <p className="mb-1">Team Members</p>
              </div>
              <div className="col-lg-2 col-6 my-3">
                <p className="font-bold text-white">Learn</p>
                <p className="mb-1">Tutorials</p>
                <p className="mb-1">How it works</p>
                <p className="mb-1">F.A.Q</p>
              </div>
              <div className="col-lg-2 col-6 my-3">
                <p className="font-bold text-white">Stories</p>
                <p className="mb-1">Blog</p>
                <p className="mb-1">Tech stories</p>
              </div>
              <div className="col-lg-2 col-6 my-3">
                <p className="font-bold text-white">Hire</p>
                <p className="mb-1">Career</p>
                <p className="mb-1">Freelancers</p>
                <p className="mb-1">Trainee</p>
              </div>
              <div className="col-lg-1 col-sm-12"></div>
            </div>
          </div>
          <div className="container">
            <hr className="line2" />
            <div className="d-flex justify-content-between social-links">
              <div className="font13">© {new Date().getFullYear()} Slate.ng, All Rights Reserved</div>
              <div>
                <a href="#!" className="each">
                  <span
                    className="iconify"
                    data-icon="fa-brands:facebook-f"
                    data-inline="false"
                  ></span>
                </a>
                <a href="#!" className="each">
                  <span
                    className="iconify"
                    data-icon="brandico:twitter-bird"
                    data-inline="false"
                  ></span>
                </a>
                <a href="#!" className="each">
                  <span
                    className="iconify"
                    data-icon="cib:google"
                    data-inline="false"
                  ></span>
                </a>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </React.Fragment>
  );
}

function Layout({ children }) {
  return (
    <React.Fragment>
      {/* Navbar */}
      <Navbar />
      {/* Layout Content */}
      {children}

      {/* Layout Footer */}
      <Footer />
    </React.Fragment>
  );
}

export default Layout;
