export default function SchoolClubs({ school }) {
  return (
    <div
      className="tab-pane fade"
      id="pills-SchoolClubs"
      role="tabpanel"
      aria-labelledby="pills-SchoolClubs-tab"
    >
      <div className="bg-white p-4 br-5">
        <div id="accordion">
          <div className="card">
            <div className="card-header">
              <div
                className="card-link"
                data-toggle="collapse"
                aria-expanded="true"
                data-target="#collapseOne"
                aria-controls="collapseOne"
              >
                <span>
                  [
                  <span className="collapsed">
                    <b> + </b>
                  </span>
                  <span className="expanded">
                    {" "}
                    <b> - </b>{" "}
                  </span>
                  ]
                </span>
                <span> Karate Club </span>
              </div>
            </div>
            <div
              id="collapseOne"
              className="collapse show"
              data-parent="#accordion"
            >
              <div className="card-body">
                Karate (空手) (/kəˈrɑːti/; Japanese pronunciation: [kaɾate]
                (About this soundlisten); Okinawan pronunciation: [kaɽati]) is a
                martial art developed in the Ryukyu Kingdom. It developed from
                the indigenous Ryukyuan martial arts (called te (手), "hand";
                tii in Okinawan) under the influence of Kung Fu, particularly
                Fujian White Crane.[1][2] Karate is now predominantly a striking
                art using punching, kicking, knee strikes, elbow strikes and
                open-hand techniques such as knife-hands, spear-hands and
                palm-heel strikes. Historically, and in some modern styles,
                grappling, throws, joint locks, restraints and vital-point
                strikes are also taught.[3] A karate practitioner is called a
                karateka (空手家), and its plural is "karateka" or "karatekas".
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-header">
              <div
                className="card-link"
                data-toggle="collapse"
                aria-expanded="false"
                data-target="#collapseTwo"
                aria-controls="collapseTwo"
              >
                <span>
                  [
                  <span className="collapsed">
                    <b> + </b>
                  </span>
                  <span className="expanded">
                    {" "}
                    <b> - </b>{" "}
                  </span>
                  ]
                </span>
                <span> Jet Club </span>
              </div>
            </div>
            <div id="collapseTwo" className="collapse" data-parent="#accordion">
              <div className="card-body">
                Karate (空手) (/kəˈrɑːti/; Japanese pronunciation: [kaɾate]
                (About this soundlisten); Okinawan pronunciation: [kaɽati]) is a
                martial art developed in the Ryukyu Kingdom. It developed from
                the indigenous Ryukyuan martial arts (called te (手), "hand";
                tii in Okinawan) under the influence of Kung Fu, particularly
                Fujian White Crane.[1][2] Karate is now predominantly a striking
                art using punching, kicking, knee strikes, elbow strikes and
                open-hand techniques such as knife-hands, spear-hands and
                palm-heel strikes. Historically, and in some modern styles,
                grappling, throws, joint locks, restraints and vital-point
                strikes are also taught.[3] A karate practitioner is called a
                karateka (空手家), and its plural is "karateka" or "karatekas".
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-header">
              <div
                className="card-link"
                data-toggle="collapse"
                aria-expanded="false"
                data-target="#collapseThree"
                aria-controls="collapseThree"
              >
                <span>
                  [
                  <span className="collapsed">
                    <b> + </b>
                  </span>
                  <span className="expanded">
                    {" "}
                    <b> - </b>{" "}
                  </span>
                  ]
                </span>
                <span> Home Economics Club </span>
              </div>
            </div>
            <div
              id="collapseThree"
              className="collapse"
              data-parent="#accordion"
            >
              <div className="card-body">
                Karate (空手) (/kəˈrɑːti/; Japanese pronunciation: [kaɾate]
                (About this soundlisten); Okinawan pronunciation: [kaɽati]) is a
                martial art developed in the Ryukyu Kingdom. It developed from
                the indigenous Ryukyuan martial arts (called te (手), "hand";
                tii in Okinawan) under the influence of Kung Fu, particularly
                Fujian White Crane.[1][2] Karate is now predominantly a striking
                art using punching, kicking, knee strikes, elbow strikes and
                open-hand techniques such as knife-hands, spear-hands and
                palm-heel strikes. Historically, and in some modern styles,
                grappling, throws, joint locks, restraints and vital-point
                strikes are also taught.[3] A karate practitioner is called a
                karateka (空手家), and its plural is "karateka" or "karatekas".
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <div
                className="card-link"
                data-toggle="collapse"
                aria-expanded="false"
                data-target="#collapseFour"
                aria-controls="collapseFour"
              >
                <span>
                  [
                  <span className="collapsed">
                    <b> + </b>
                  </span>
                  <span className="expanded">
                    {" "}
                    <b> - </b>{" "}
                  </span>
                  ]
                </span>
                <span> Farmers Club</span>
              </div>
            </div>
            <div
              id="collapseFour"
              className="collapse"
              data-parent="#accordion"
            >
              <div className="card-body">
                Karate (空手) (/kəˈrɑːti/; Japanese pronunciation: [kaɾate]
                (About this soundlisten); Okinawan pronunciation: [kaɽati]) is a
                martial art developed in the Ryukyu Kingdom. It developed from
                the indigenous Ryukyuan martial arts (called te (手), "hand";
                tii in Okinawan) under the influence of Kung Fu, particularly
                Fujian White Crane.[1][2] Karate is now predominantly a striking
                art using punching, kicking, knee strikes, elbow strikes and
                open-hand techniques such as knife-hands, spear-hands and
                palm-heel strikes. Historically, and in some modern styles,
                grappling, throws, joint locks, restraints and vital-point
                strikes are also taught.[3] A karate practitioner is called a
                karateka (空手家), and its plural is "karateka" or "karatekas".
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <div
                className="card-link"
                data-toggle="collapse"
                aria-expanded="false"
                data-target="#collapseFive"
                aria-controls="collapseFive"
              >
                <span>
                  [
                  <span className="collapsed">
                    <b> + </b>
                  </span>
                  <span className="expanded">
                    {" "}
                    <b> - </b>{" "}
                  </span>
                  ]
                </span>
                <span> Debate Club </span>
              </div>
            </div>
            <div
              id="collapseFive"
              className="collapse"
              data-parent="#accordion"
            >
              <div className="card-body">
                Karate (空手) (/kəˈrɑːti/; Japanese pronunciation: [kaɾate]
                (About this soundlisten); Okinawan pronunciation: [kaɽati]) is a
                martial art developed in the Ryukyu Kingdom. It developed from
                the indigenous Ryukyuan martial arts (called te (手), "hand";
                tii in Okinawan) under the influence of Kung Fu, particularly
                Fujian White Crane.[1][2] Karate is now predominantly a striking
                art using punching, kicking, knee strikes, elbow strikes and
                open-hand techniques such as knife-hands, spear-hands and
                palm-heel strikes. Historically, and in some modern styles,
                grappling, throws, joint locks, restraints and vital-point
                strikes are also taught.[3] A karate practitioner is called a
                karateka (空手家), and its plural is "karateka" or "karatekas".
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
