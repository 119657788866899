import mapPng from "../../../assets/img/map.png";


export default function Contact({ school }) {
  return (
    <div
      className="tab-pane fade"
      id="pills-Contact"
      role="tabpanel"
      aria-labelledby="pills-Contact-tab"
    >
      <div className="bg-white p-4">
        <div className="row row-reverse">
          <div className="col-lg-4 col-md-12 col-sm-12 mt-3">
            <p className="font20 mb-2">Location</p>
            <p className="font-light">
              29 Mambilla Street, Three Arms Zone, Aso drive, Fct, Abuja
            </p>
          </div>
          <div className="col-lg-8 col-md-12 col-sm-12 mt-3">
            <img src={mapPng} alt="" className="w-100" />
          </div>
        </div>
        <div className="row row-reverse">
          <div className="col-lg-4 col-md-12 col-sm-12 mt-3">
            <p className="font20 mb-2">Phone numbers</p>
            <p className="font-light">
              +234 70860 99999 <br />
              +234 91329 85951
            </p>
            <div className="mt-5">
              <p className="font20 mb-2">Connect with us</p>
              <div>
                <span className="mr-3">
                  <a href="#!" target="_blanc">
                    <span
                      className="iconify"
                      data-icon="icomoon-free:facebook"
                      data-inline="false"
                    ></span>
                  </a>
                </span>

                <span className="mr-3">
                  <a href="#!" target="_blanc">
                    <span
                      className="iconify"
                      data-icon="topcoat:twitter"
                      data-inline="false"
                    ></span>
                  </a>
                </span>

                <span>
                  <a href="#!" target="_blanc">
                    <span
                      className="iconify"
                      data-icon="brandico:linkedin"
                      data-inline="false"
                    ></span>
                  </a>
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-md-12 col-sm-12 mt-3">
            <form>
              <div className="row">
                <div className="col-md-6 col-sm-12 mb-3">
                  <div>
                    <label>First Name</label>
                    <input
                      type="text"
                      placeholder="First Name"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-6 col-sm-12 mb-3">
                  <div>
                    <label>Last Name</label>
                    <input
                      type="text"
                      placeholder="Last Name"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-12 mb-3">
                  <div>
                    <label>Email Address</label>
                    <input
                      type="text"
                      placeholder="example@gmail.com"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-12 mb-3">
                  <div>
                    <label>Leave a Message</label>
                    <textarea
                      rows="3"
                      placeholder="Message"
                      className="form-control"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="my-4">
                <button className="btn btn-blue px-5">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
