export default function Profile({ school }) {
  return (
    <div
      className="tab-pane fade"
      id="pills-Profile"
      role="tabpanel"
      aria-labelledby="pills-Profile-tab"
    >
      <div className="bg-white p-4 br-5">
        <div className="mt-3 font24">Profile</div>
        <hr />
        <div>
          Marriot Primary School is an all-girls, independent day and boarding
          school located in the city of Victoria, on the scenic west coast of
          British Columbia, Canada. Founded in 1908, SMS is an international
          centre for girls’ learning and leadership development, providing
          empowering education for girls from Junior Kindergarten to Grade 12
          (Boarding: Grades 7–12). <br />
          <br />
          SMS values diversity and inspires excellence of mind and strength of
          spirit. Students here are challenged to take responsibility for their
          own learning experience; to develop individual worth, academic
          excellence, personal wellness, and a connection with nature; and to
          provide service to their local and global community.
        </div>
        <div className="mt-4 font24">Areas of Expertise</div>
        <hr />
        <div>
          We specialize in the education of girls. Our teachers know how girls
          learn best and use classroom techniques that draw on their strengths.{" "}
          <br />
          <br />
          The result is a learning environment where girls are engaged, take
          risks and gain confidence and where every student has the opportunity
          to take on new challenges and leadership roles in all areas of school
          life.
        </div>
        <div className="mt-4 font24">Campus Tour</div>
        <hr />
        <div>
          Campus tours can be scheduled anytime. Please contact our admissions
          office to schedule your tour: admissions@stmarg.ca.
        </div>
        <div className="mt-4 text-grey font12">Modified on Nov 11, 2020</div>
      </div>
    </div>
  );
}
