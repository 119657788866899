// import useAxios from "./config";
import axios from "axios";

/***************************************/
// Base URL for constructing API request
const BASE_URL = "https://stagingapi.slate.ng";

const SCHOOLS_URL = `${BASE_URL}/finder`;

const SCHOOL_DETAILS_URL = `${SCHOOLS_URL}/school`;

// const SCHOOL_BANNER_URL = `${SCHOOLS_URL}/school/:id/banner`;

// const SCHOOL_AVATAR_URL = `${SCHOOLS_URL}/school/:id/avatar?file`;
/***************************************/

// configure axios
// const axios = useAxios();

// GET all schools (private and public)
async function fetchSchools(query) {
  const response = await axios.get(`${SCHOOLS_URL}/?${query || ""}`);
  return response.data;
}

async function fetchSchoolDetails(id) {
  if (id !== undefined) {
    const response = await axios.get(`${SCHOOL_DETAILS_URL}/${id}`);
    return response.data;
  }
  throw Error("School ID missing, can't fetch school details at the moment!");
}

// async function fetchSchoolBanner(id) {
//   if (id !== undefined) {
//     const response = await axios.get(SCHOOL_BANNER_URL);
//     return response.data;
//   }
//   throw Error("School ID missing, can't fetch school Banner!");
// }

// async function updateSchoolAvatar(id, file) {
//   if (id !== undefined) {
//     const response = await axios.get(SCHOOL_BANNER_URL);
//     return response;
//   }
//   throw Error("School ID missing, can't update school Avatar!");
// }

const queryFunctions = {
  fetchSchools,
  fetchSchoolDetails,
}

export default queryFunctions