import schoolImage from "../../../assets/img/school-img.png";
import schoolImagePng from "../../../assets/img/school-img3.png";

export default function Features({ school }) {
  return (
    <div
      className="tab-pane fade"
      id="pills-Features"
      role="tabpanel"
      aria-labelledby="pills-Features-tab"
    >
      <div className="bg-white p-4 br-5">
        <div id="accordion2">
          <div className="card">
            <div className="card-header">
              <div
                className="card-link"
                data-toggle="collapse"
                aria-expanded="true"
                data-target="#collapseVideo"
                aria-controls="collapseVideo"
              >
                <span>
                  [
                  <span className="collapsed">
                    <b> + </b>
                  </span>
                  <span className="expanded">
                    {" "}
                    <b> - </b>{" "}
                  </span>
                  ]
                </span>
                <span> Featured Videos </span>
              </div>
            </div>
            <div
              id="collapseVideo"
              className="collapse show"
              data-parent="#accordion2"
            >
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6 col-sm-12 mb-2">
                    <img
                      src={schoolImage}
                      alt=""
                      className="w-100 school-img"
                    />
                    <div className="bg-blue font12 px-3 py-2 text-white">
                      <div className="mb-3 font-light">
                        Why you will want your child to get an education at our
                        new marriot college campus in maitama
                      </div>
                      <div className="text-grey">Jun 6, 2020 •</div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 mb-2">
                    <img
                      src={schoolImage}
                      alt=""
                      className="w-100 school-img"
                    />
                    <div className="bg-blue font12 px-3 py-2 text-white">
                      <div className="mb-3 font-light">
                        Why you will want your child to get an education at our
                        new marriot college campus in maitama
                      </div>
                      <div className="text-grey">Jun 6, 2020 •</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-header">
              <div
                className="card-link"
                data-toggle="collapse"
                aria-expanded="false"
                data-target="#collapseNews"
                aria-controls="collapseNews"
              >
                <span>
                  [
                  <span className="collapsed">
                    <b> + </b>
                  </span>
                  <span className="expanded">
                    {" "}
                    <b> - </b>{" "}
                  </span>
                  ]
                </span>
                <span> Featured News</span>
              </div>
            </div>
            <div
              id="collapseNews"
              className="collapse"
              data-parent="#accordion2"
            >
              <div className="card-body">
                <div className="bg-darkblue p-4 mb-3">
                  <div className="d-flex sm-reverse">
                    <div className="text-white">
                      <div className="mb-3 font-light opacity-8">
                        Why you will want your child to get an education at our
                        new marriot college campus in maitama
                      </div>
                      <div className="opacity-4">Jun 6, 2020 •</div>
                    </div>
                    <div>
                      <img src={schoolImagePng} alt="" className="news-img" />
                    </div>
                  </div>
                </div>
                <div className="bg-darkblue p-4 mb-3">
                  <div className="d-flex sm-reverse">
                    <div className="text-white">
                      <div className="mb-3 font-light opacity-8">
                        Why you will want your child to get an education at our
                        new marriot college campus in maitama
                      </div>
                      <div className="opacity-4">Jun 6, 2020 •</div>
                    </div>
                    <div>
                      <img src={schoolImagePng} alt="" className="news-img" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
