export default function Fees({ school }) {
  return (
    <div
      className="tab-pane fade"
      id="pills-Fees"
      role="tabpanel"
      aria-labelledby="pills-Fees-tab"
    >
      <div className="bg-white p-4 br-5">
        <div className="mt-3 font24">Tuition Fees</div>
        <hr />
        <div className="my-3 font14">
          <div className="d-flex">
            <div className="mr-3 font-bold">
              <div>
                <b>Provincial Students:</b>
              </div>
            </div>
            <div className="font-light">
              <div>$14,180 - $25,615</div>
            </div>
          </div>
          <div className="d-flex mt-2">
            <div className="mr-3 font-bold">
              <div>
                <b>International Students:</b>
              </div>
            </div>
            <div className="font-light">
              <div>$22,690 - $33,120</div>
            </div>
          </div>
        </div>
        <div className="mt-4 font24">Notes for Nigerian Students:</div>
        <hr />
        <div>
          At SMS, we are fortunate to deliver a quality education while enjoying
          a beautiful campus, superior facilities, and specialty teachers who
          know how girls learn best. <br />
          <br />
          <b>Highlights of what’s included in your tuition:</b>
          <br />
          <br />
          Outweek - a week-long outdoor adventure offered each autumn - with
          challenging and rewarding opportunities for the whole school; <br />
          <br />
          50 internally - staffed extracurricular clubs and athletic activities;{" "}
          <br />
          <br />
          School supplies for the Foundation and Middle Years;
          <br />
          <br />
          45 minutes of before - and after-school supervision for the Foundation
          and Middle Years;
          <br />
          <br />
          Specialist teachers, expert guest speakers, and individualized
          programs at all levels;
          <br />
          <br />
          An internationally-recognized residence program that enriches our
          school culture by attracting students from all over the world;
          <br />
          <br />
          Mentored student leadership opportunities including councils and
          committees that elevate girls’ voices and build confidence;
          <br />
          <br />
          Experiential programs including community service and self-directed
          learning tailored to student interests;
          <br />
          <br />
          Please visit our website for the most up-to-date tuition fee schedule.
        </div>
      </div>
    </div>
  );
}
