import React from "react";
import "./styles.scoped.scss";
import emptyHeart from "../../assets/img/empty-love.png";
import schoolImage from "../../assets/img/school-img.png";
import { Link } from "react-router-dom";

function Bookmark() {
  const state = false;
  return (
    <React.Fragment>
      <section class="d-body">
        <section className="container">
          <div class="mb-3 mt-4 saved-school-tab">
            <span> Saved Schools </span>
            <span class="count ml-2">{state ? 4 : 0}</span>
          </div>
          {state && (
            <div>
              <div class="row my-4">
                <div class="col-lg-3 col-md-4 col-sm-6 my-3 p-r d-flex align-items-start">
                  <input
                    type="text"
                    class="form-control d-inline-block pr-5"
                    placeholder="Search Schools.."
                  />
                  <span class="search-icon position-relative float-right align-middle ml-n5">
                    <span
                      class="iconify"
                      data-icon="feather:search"
                      data-inline="false"
                    ></span>
                  </span>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-4 my-3 ml-auto">
                  <div class="dropdown">
                    <div
                      class="dropdown-toggle"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Sort By: Type
                      <span class="ml-1 text-grey">
                        <span
                          class="iconify"
                          data-icon="fa:angle-down"
                          data-inline="false"
                        />
                      </span>
                    </div>
                    <div
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <div class="dropdown-item">Type</div>
                      <div class="dropdown-item">Type2</div>
                      <div class="dropdown-item">Type3</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-5">
                <div class="col-lg-3 col-md-4 col-sm-6 my-3">
                  <div class="school-card">
                    <Link
                      to="/schools/marriot-college"
                      style={{ textDecoration: "none" }}
                    >
                      <img src={schoolImage} alt="" class="school-img" />
                      <div class="p-4">
                        <p class="mb-0 font18">Marriot College</p>
                        <p class="text-grey font-light">
                          23 Nottingham Street, Wuse 2 Area
                        </p>
                        <div class="d-flex flex-wrap justify-content-between">
                          <div class="d-flex mb-2">
                            <div class="mr-3">
                              <div class="font12 text-grey">Curriculum</div>
                              <div class="font13 text-blue">British</div>
                            </div>
                            <div>
                              <div class="font12 text-grey">Type</div>
                              <div class="font13 text-blue">Private</div>
                            </div>
                          </div>
                          <div class="d-flex align-items-center mb-2">
                            <div class="mr-3">
                              <div class="font12 text-grey">Co-Ed</div>
                              <div class="font13 text-blue">Yes</div>
                            </div>
                            <div>
                              <span
                                class="iconify"
                                data-icon="bi:bookmark-fill"
                                data-inline="false"
                              ></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 my-3">
                  <div class="school-card">
                    <Link
                      to="/schools/marriot-college"
                      style={{ textDecoration: "none" }}
                    >
                      <img src={schoolImage} alt="" class="school-img" />
                      <div class="p-4">
                        <p class="mb-0 font18">Marriot College</p>
                        <p class="text-grey font-light">
                          23 Nottingham Street, Wuse 2 Area
                        </p>
                        <div class="d-flex flex-wrap justify-content-between">
                          <div class="d-flex mb-2">
                            <div class="mr-3">
                              <div class="font12 text-grey">Curriculum</div>
                              <div class="font13 text-blue">British</div>
                            </div>
                            <div>
                              <div class="font12 text-grey">Type</div>
                              <div class="font13 text-blue">Private</div>
                            </div>
                          </div>
                          <div class="d-flex align-items-center mb-2">
                            <div class="mr-3">
                              <div class="font12 text-grey">Co-Ed</div>
                              <div class="font13 text-blue">Yes</div>
                            </div>
                            <div>
                              <span
                                class="iconify"
                                data-icon="bi:bookmark-fill"
                                data-inline="false"
                              ></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 my-3">
                  <div class="school-card">
                    <Link
                      to="/schools/marriot-college"
                      style={{ textDecoration: "none" }}
                    >
                      <img src={schoolImage} alt="" class="school-img" />
                      <div class="p-4">
                        <p class="mb-0 font18">Marriot College</p>
                        <p class="text-grey font-light">
                          23 Nottingham Street, Wuse 2 Area
                        </p>
                        <div class="d-flex flex-wrap justify-content-between">
                          <div class="d-flex mb-2">
                            <div class="mr-3">
                              <div class="font12 text-grey">Curriculum</div>
                              <div class="font13 text-blue">British</div>
                            </div>
                            <div>
                              <div class="font12 text-grey">Type</div>
                              <div class="font13 text-blue">Private</div>
                            </div>
                          </div>
                          <div class="d-flex align-items-center mb-2">
                            <div class="mr-3">
                              <div class="font12 text-grey">Co-Ed</div>
                              <div class="font13 text-blue">Yes</div>
                            </div>
                            <div>
                              <span
                                class="iconify"
                                data-icon="bi:bookmark-fill"
                                data-inline="false"
                              ></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 my-3">
                  <div class="school-card">
                    <Link
                      to="/schools/marriot-college"
                      style={{ textDecoration: "none" }}
                    >
                      <img src={schoolImage} alt="" class="school-img" />
                      <div class="p-4">
                        <p class="mb-0 font18">Marriot College</p>
                        <p class="text-grey font-light">
                          23 Nottingham Street, Wuse 2 Area
                        </p>
                        <div class="d-flex flex-wrap justify-content-between">
                          <div class="d-flex mb-2">
                            <div class="mr-3">
                              <div class="font12 text-grey">Curriculum</div>
                              <div class="font13 text-blue">British</div>
                            </div>
                            <div>
                              <div class="font12 text-grey">Type</div>
                              <div class="font13 text-blue">Private</div>
                            </div>
                          </div>
                          <div class="d-flex align-items-center mb-2">
                            <div class="mr-3">
                              <div class="font12 text-grey">Co-Ed</div>
                              <div class="font13 text-blue">Yes</div>
                            </div>
                            <div>
                              <span
                                class="iconify"
                                data-icon="bi:bookmark-fill"
                                data-inline="false"
                              ></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div class="empty my-5">
            <div class="d-flex align-items-center flex-wrap">
              <div class="mr-5">
                <img src={emptyHeart} alt="" />
              </div>
              <div>
                <p>
                  You don’t have any saved schools yet. <br />
                  Tap the
                  <span class="mx-1">
                    <span
                      class="iconify"
                      data-icon="bi:bookmark-fill"
                      data-inline="false"
                    />
                  </span>
                  icon on schools to save them for later.
                </p>
                <Link to="/categories" class="btn btn-darkblue">
                  School Category
                </Link>
              </div>
            </div>
          </div>
        </section>
      </section>
    </React.Fragment>
  );
}

export default Bookmark;
