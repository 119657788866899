export default function Admissions({ school }) {
  return (
    <div
      className="tab-pane fade"
      id="pills-Admissions"
      role="tabpanel"
      aria-labelledby="pills-Admissions-tab"
    >
      <div className="bg-white p-4 br-5">
        <div className="mt-3 font24">Admissions</div>
        <hr />
        <div className="mb-5"></div>
      </div>
    </div>
  );
}
